import React from 'react';
import Layout from '../layouts/Layout';
import { PageProps, graphql, useStaticQuery } from 'gatsby';

const IndexPage = (props: PageProps) => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);

	return (
		<Layout
			title={'Dream Finders Homes: Careers'}
			desc={'Dream Finders Homes: Careers'}
			location={props.location}
		>
			<div className={'container mx-auto py-20'}>
				<h1 className={'text-center text-4xl'}>
					<span className={'text-bt-red text-9xl'}>404</span> <br />{' '}
					Page Not Found.
				</h1>
			</div>
		</Layout>
	);
};

export default IndexPage;
